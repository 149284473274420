import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import { appName, getJsonItemFromLocalStorage } from '../../helpers/helpers'
import { Button } from '../fields/Button'
import PasswordField from '../fields/PasswordField'
import SaveButton from '../fields/SaveButton'
import TextField from '../fields/TextField'
import * as validations from '../fields/Validations'
import Alert from '../ui/Alert'
import LoginHeader from '../users/LoginHeader'

const LoginForm = ({ handleSubmitForm, handleIdGobPeButton }) => {
  const [valActionAlert, setActionAlert] = useState(getJsonItemFromLocalStorage('actionAlert'))

  return (
    <Form
      onSubmit={handleSubmitForm}
      subscription={{ submitError: true, submitting: true, pristine: true }}
      render={({ handleSubmit, submitError, submitting, pristine }) => (
        <form className="pt-8 md:pt-14 pb-14 md:pb-18 m-auto max-w-xl" onSubmit={handleSubmit}>
          <LoginHeader
            title={appName}
            subtitle="Revisa tus documentos, el avance de tus trámites y las notificaciones que te envían las entidades públicas."
            className="pb-7.5 md:pb-10 md:text-center"
          />
          {valActionAlert && (
            <Alert type={valActionAlert.type} autoHideDuration={5000} setActionAlert={setActionAlert}>
              <span>{`${valActionAlert.text}`}</span>
            </Alert>
          )}
          <section className="p-6 md:p-9 border border-blue-200 rounded shadow-auth-register">
            <h2 className="font-bold text-center text-xl md:text-2xl pb-6 md:pb-7.5">
              Ingresa tus datos para iniciar sesión
            </h2>
            <TextField label="Número de DNI" name="document_number" size="full" validate={validations.required()} />
            <PasswordField
              name="password"
              label="Ingresa tu contraseña"
              size="full"
              labelClassName="mb-2"
              checkboxLabel="Mostrar contraseña"
              checkboxName="show_password"
              checkboxClassName="pb-7"
              validate={validations.required()}
            />
            {submitError && <p className="text-red-500 font-bold text-center pb-6">{submitError}</p>}
            <SaveButton label="Ingresar" size="full" disabled={submitting || pristine} />
            <article className="border-b border-gray-250 text-center py-6 mb-6">
              <p className="font-medium">¿Olvidaste tu contraseña?</p>
              <Link to="/recuperar-contrasena" className="font-medium text-blue-700 underline">
                Genera una nueva contraseña
              </Link>
              <p>o</p>
              <Link to="#" className="font-medium text-blue-700 underline">
                Inicia sesión con un código que enviaremos a tu correo
              </Link>
            </article>
            <Button size="full" variant="secondary" onClick={handleIdGobPeButton}>
              Ingresar con ID Gob.pe
            </Button>
          </section>
        </form>
      )}
    />
  )
}

LoginForm.propTypes = {
  handleSubmitForm: PropTypes.func,
  handleIdGobPeButton: PropTypes.func
}

export default LoginForm
