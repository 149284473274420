import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'

import AlertMessage from '~/components/notifications/AlertMessage'

import AutoSave from '../../components/AutoSave'
import { Container } from '../../components/Container'
import Feedback from '../../components/feedback/Feedback'
import { MessagesFilters } from '../../components/notifications/MessagesFilters'
import { MessagesTable } from '../../components/notifications/MessagesTable'
import HeadingTag from '../../components/ui/HeadingTag'
import { Pagination } from '../../components/ui/Pagination'
import useFilters from '../../hooks/useFilters'

export const MessagesPage = () => {
  const [messages, setMessages] = useState([])

  const { onFilter, results, initialFilters, status } = useFilters('messages-user.json', {
    sort: 'sent_in desc',
    message_type: 'notification'
  })

  useEffect(() => {
    if (!results.data?.length) return
    setMessages(results.data.map(message => ({ ...message, isChecked: false })))
  }, [results])

  const showContent = () => {
    if (status === 'loading') return <div className="font-bold flex justify-center">Cargando...</div>
    if (!results.data?.length)
      return <div className="font-bold flex justify-center">No se encontraron notificaciones</div>
    if (results.data.length) {
      return (
        <>
          <MessagesTable messages={messages} setMessages={setMessages} />
          <Pagination numPages={results.total_pages} />
        </>
      )
    }
  }

  return (
    <Container className="md:h-auto">
      <div className="px-5 md:px-20 pt-8 pb-12 md:pt-14 md:pb-0">
        <section className="mb-8 md:mb-9">
          <HeadingTag
            title="Mis notificaciones y comunicaciones"
            id="messages-label"
            className="font-bold text-3xl md:text-4xl"
          />
          <p className="pt-4 md:pt-5">
            Recibe las notificaciones sobre tus trámites y las comunicaciones de interés que envían las entidades.
          </p>
        </section>
        <Form onSubmit={onFilter} initialValues={initialFilters}>
          {({ handleSubmit }) => (
            <form className="block md:flex" onSubmit={handleSubmit}>
              <AlertMessage>
                <AutoSave debounce={1000} save={onFilter} />
                <MessagesFilters />
                <div className="flex-auto">{showContent()}</div>
              </AlertMessage>
            </form>
          )}
        </Form>
      </div>
      <Feedback senderType="citizen" />
    </Container>
  )
}
